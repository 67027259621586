import BaseLayout from "../components/BaseLayout/BaseLayout";

const PageDoesntExist = () => {
  return (
    <div className="text-center mt-12">
      <h2>404</h2>Page does not exist
    </div>
  );
};

export default () => (
  <BaseLayout>
    <PageDoesntExist />
  </BaseLayout>
);
